import {
  DowntimeConsequence,
  InjuryConsequence,
  Likelihood,
  LikelihoodDetailed,
  PercentageRange,
  RiskAppetite,
  RiskRatingEnum,
} from "@/apis/services/HazardService";

const downtime: { [riskLevel: string]: { [apetite: string]: Object } } = {
  [RiskAppetite.NEUTRAL]: {
    [Likelihood.Value011]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.Medium,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.High,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.HighVeryHigh,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.VeryHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Catastrophic,
    },
    [Likelihood.Value110]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.High,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.HighVeryHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.VeryHigh,
    },
    [Likelihood.Value10100]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.Low,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.High,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.HighVeryHigh,
    },
    [Likelihood.Value1001000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.Low,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.High,
    },
    [Likelihood.Value100010000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.Low,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.MediumHigh,
    },
    [Likelihood.Value10000100000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.Low,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Medium,
    },
    [Likelihood.Value1000001000000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.Low,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.LowMedium,
    },
  },
  [RiskAppetite.AVERSE]: {
    [Likelihood.Value011]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.High,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.HighVeryHigh,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.VeryHigh,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.Catastrophic,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Catastrophic,
    },
    [Likelihood.Value110]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.Medium,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.High,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.HighVeryHigh,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.VeryHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Catastrophic,
    },
    [Likelihood.Value10100]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.High,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.HighVeryHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.VeryHigh,
    },
    [Likelihood.Value1001000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.Low,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.High,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.HighVeryHigh,
    },
    [Likelihood.Value100010000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.Low,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.High,
    },
    [Likelihood.Value10000100000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.Low,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.MediumHigh,
    },
    [Likelihood.Value1000001000000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.Low,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Medium,
    },
  },
  [RiskAppetite.TOLERANT]: {
    [Likelihood.Value011]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.High,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.HighVeryHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.VeryHigh,
    },
    [Likelihood.Value110]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.Low,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.High,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.HighVeryHigh,
    },
    [Likelihood.Value10100]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.Low,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.MediumHigh,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.High,
    },
    [Likelihood.Value1001000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.Low,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.Medium,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.MediumHigh,
    },
    [Likelihood.Value100010000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.Low,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.LowMedium,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Medium,
    },
    [Likelihood.Value10000100000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.Low,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.LowMedium,
    },
    [Likelihood.Value1000001000000]: {
      [DowntimeConsequence.MINUTES]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.HOURS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.DAYS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.WEEKS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.MONTHS]: RiskRatingEnum.VeryLow,
      [DowntimeConsequence.YEARS]: RiskRatingEnum.Low,
    },
  },
};

const lifeSafety: { [riskLevel: string]: { [apetite: string]: Object } } = {
  [RiskAppetite.NEUTRAL]: {
    [LikelihoodDetailed.Value0103]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value1030]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.High,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value30100]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.High,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value100300]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Medium,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.High,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value3001000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Medium,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.High,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value10003000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Medium,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value300010000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Medium,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.VeryLow,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Low,
    },
  },
  [RiskAppetite.AVERSE]: {
    [LikelihoodDetailed.Value0103]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value1030]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value30100]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.High,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value100300]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.High,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value3001000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Medium,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.High,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value10003000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Medium,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.High,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value300010000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Medium,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Medium,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Low,
    },
  },
  [RiskAppetite.TOLERANT]: {
    [LikelihoodDetailed.Value0103]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.High,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Catastrophic,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value1030]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.High,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.VeryHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value30100]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Medium,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.High,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.HighVeryHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value100300]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Medium,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.High,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value3001000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Medium,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.MediumHigh,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value10003000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Medium,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value300010000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.LowMedium,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [InjuryConsequence.MINOR]: RiskRatingEnum.Low,
      [InjuryConsequence.MAJOR]: RiskRatingEnum.Low,
      [InjuryConsequence.SEVERE]: RiskRatingEnum.Low,
      [InjuryConsequence.VERY_SEVER]: RiskRatingEnum.Low,
      [InjuryConsequence.FATALITY]: RiskRatingEnum.Low,
    },
  },
};

const economicLoss: { [riskLevel: string]: { [apetite: string]: Object } } = {
  [RiskAppetite.NEUTRAL]: {
    [LikelihoodDetailed.Value0103]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [PercentageRange.Value13]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [PercentageRange.Value13]: RiskRatingEnum.High,
      [PercentageRange.Value310]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [PercentageRange.Value13]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value310]: RiskRatingEnum.High,
      [PercentageRange.Value1030]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value1030]: {
      [PercentageRange.Value13]: RiskRatingEnum.Medium,
      [PercentageRange.Value310]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.High,
      [PercentageRange.Value30100]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value30100]: {
      [PercentageRange.Value13]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value310]: RiskRatingEnum.Medium,
      [PercentageRange.Value1030]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value100300]: {
      [PercentageRange.Value13]: RiskRatingEnum.Low,
      [PercentageRange.Value310]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value1030]: RiskRatingEnum.Medium,
      [PercentageRange.Value30100]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value3001000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.Low,
      [PercentageRange.Value1030]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value30100]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value10003000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.Low,
      [PercentageRange.Value30100]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value300010000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
  },
  [RiskAppetite.AVERSE]: {
    [LikelihoodDetailed.Value0103]: {
      [PercentageRange.Value13]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value310]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [PercentageRange.Value13]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [PercentageRange.Value13]: RiskRatingEnum.High,
      [PercentageRange.Value310]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value1030]: {
      [PercentageRange.Value13]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value310]: RiskRatingEnum.High,
      [PercentageRange.Value1030]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value30100]: {
      [PercentageRange.Value13]: RiskRatingEnum.Medium,
      [PercentageRange.Value310]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.High,
      [PercentageRange.Value30100]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value100300]: {
      [PercentageRange.Value13]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value310]: RiskRatingEnum.Medium,
      [PercentageRange.Value1030]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value3001000]: {
      [PercentageRange.Value13]: RiskRatingEnum.Low,
      [PercentageRange.Value310]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value1030]: RiskRatingEnum.Medium,
      [PercentageRange.Value30100]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value10003000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.Low,
      [PercentageRange.Value1030]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value30100]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value300010000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.Low,
      [PercentageRange.Value30100]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
  },
  [RiskAppetite.TOLERANT]: {
    [LikelihoodDetailed.Value0103]: {
      [PercentageRange.Value13]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [PercentageRange.Value13]: RiskRatingEnum.High,
      [PercentageRange.Value310]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [PercentageRange.Value13]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value310]: RiskRatingEnum.High,
      [PercentageRange.Value1030]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value310]: {
      [PercentageRange.Value13]: RiskRatingEnum.Medium,
      [PercentageRange.Value310]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.High,
      [PercentageRange.Value30100]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value1030]: {
      [PercentageRange.Value13]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value310]: RiskRatingEnum.Medium,
      [PercentageRange.Value1030]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value30100]: {
      [PercentageRange.Value13]: RiskRatingEnum.Low,
      [PercentageRange.Value310]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value1030]: RiskRatingEnum.Medium,
      [PercentageRange.Value30100]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value100300]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.Low,
      [PercentageRange.Value1030]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value30100]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value3001000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.Low,
      [PercentageRange.Value30100]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value10003000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value300010000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
  },
};

const inventoryLoss: { [riskLevel: string]: { [apetite: string]: Object } } = {
  [RiskAppetite.NEUTRAL]: {
    [LikelihoodDetailed.Value0103]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [PercentageRange.Value13]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [PercentageRange.Value13]: RiskRatingEnum.High,
      [PercentageRange.Value310]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [PercentageRange.Value13]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value310]: RiskRatingEnum.High,
      [PercentageRange.Value1030]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value1030]: {
      [PercentageRange.Value13]: RiskRatingEnum.Medium,
      [PercentageRange.Value310]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.High,
      [PercentageRange.Value30100]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value30100]: {
      [PercentageRange.Value13]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value310]: RiskRatingEnum.Medium,
      [PercentageRange.Value1030]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value100300]: {
      [PercentageRange.Value13]: RiskRatingEnum.Low,
      [PercentageRange.Value310]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value1030]: RiskRatingEnum.Medium,
      [PercentageRange.Value30100]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value3001000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.Low,
      [PercentageRange.Value1030]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value30100]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value10003000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.Low,
      [PercentageRange.Value30100]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value300010000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
  },
  [RiskAppetite.AVERSE]: {
    [LikelihoodDetailed.Value0103]: {
      [PercentageRange.Value13]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value310]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [PercentageRange.Value13]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value310]: {
      [PercentageRange.Value13]: RiskRatingEnum.High,
      [PercentageRange.Value310]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value1030]: {
      [PercentageRange.Value13]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value310]: RiskRatingEnum.High,
      [PercentageRange.Value1030]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value30100]: {
      [PercentageRange.Value13]: RiskRatingEnum.Medium,
      [PercentageRange.Value310]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.High,
      [PercentageRange.Value30100]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value100300]: {
      [PercentageRange.Value13]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value310]: RiskRatingEnum.Medium,
      [PercentageRange.Value1030]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value3001000]: {
      [PercentageRange.Value13]: RiskRatingEnum.Low,
      [PercentageRange.Value310]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value1030]: RiskRatingEnum.Medium,
      [PercentageRange.Value30100]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value10003000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.Low,
      [PercentageRange.Value1030]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value30100]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value300010000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.Low,
      [PercentageRange.Value30100]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
  },
  [RiskAppetite.TOLERANT]: {
    [LikelihoodDetailed.Value0103]: {
      [PercentageRange.Value13]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value310]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.Catastrophic,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value031]: {
      [PercentageRange.Value13]: RiskRatingEnum.High,
      [PercentageRange.Value310]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.Catastrophic,
    },
    [LikelihoodDetailed.Value13]: {
      [PercentageRange.Value13]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value310]: RiskRatingEnum.High,
      [PercentageRange.Value1030]: RiskRatingEnum.HighVeryHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryHigh,
    },
    [LikelihoodDetailed.Value310]: {
      [PercentageRange.Value13]: RiskRatingEnum.Medium,
      [PercentageRange.Value310]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value1030]: RiskRatingEnum.High,
      [PercentageRange.Value30100]: RiskRatingEnum.HighVeryHigh,
    },
    [LikelihoodDetailed.Value1030]: {
      [PercentageRange.Value13]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value310]: RiskRatingEnum.Medium,
      [PercentageRange.Value1030]: RiskRatingEnum.MediumHigh,
      [PercentageRange.Value30100]: RiskRatingEnum.High,
    },
    [LikelihoodDetailed.Value30100]: {
      [PercentageRange.Value13]: RiskRatingEnum.Low,
      [PercentageRange.Value310]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value1030]: RiskRatingEnum.Medium,
      [PercentageRange.Value30100]: RiskRatingEnum.MediumHigh,
    },
    [LikelihoodDetailed.Value100300]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.Low,
      [PercentageRange.Value1030]: RiskRatingEnum.LowMedium,
      [PercentageRange.Value30100]: RiskRatingEnum.Medium,
    },
    [LikelihoodDetailed.Value3001000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.Low,
      [PercentageRange.Value30100]: RiskRatingEnum.LowMedium,
    },
    [LikelihoodDetailed.Value10003000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.Low,
    },
    [LikelihoodDetailed.Value300010000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value1000030000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value30000100000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value100000300000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
    [LikelihoodDetailed.Value3000001000000]: {
      [PercentageRange.Value13]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value310]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value1030]: RiskRatingEnum.VeryLow,
      [PercentageRange.Value30100]: RiskRatingEnum.VeryLow,
    },
  },
};

export interface IRiskRatingColorObject {
  [riskLevelName: string]: {
    color: string;
    bars?: number;
    pixel?: number;
    exponentialPixel?: number;
  };
}

export const classOneRatingColors: IRiskRatingColorObject = {
  [RiskRatingEnum.Catastrophic]: {
    color: "#240505",
    bars: 9,
    pixel: 900,
    exponentialPixel: 27 * 27 * 6,
  },
  [RiskRatingEnum.VeryHigh]: {
    color: "#4D0F65",
    bars: 8,
    pixel: 512,
    exponentialPixel: 27 * 18 * 3,
  },
  [RiskRatingEnum.HighVeryHigh]: {
    color: "#880E0E",
    bars: 7,
    pixel: 256,
    exponentialPixel: 27 * 18,
  },
  [RiskRatingEnum.High]: {
    color: "#E51A1A",
    bars: 6,
    pixel: 128,
    exponentialPixel: 27 * 6,
  },
  [RiskRatingEnum.MediumHigh]: {
    color: "#E77E2F",
    bars: 5,
    pixel: 64,
    exponentialPixel: 27 * 3,
  },
  [RiskRatingEnum.Medium]: {
    color: "#FFDA47",
    bars: 4,
    pixel: 32,
    exponentialPixel: 27,
  },
  [RiskRatingEnum.LowMedium]: {
    color: "#BDD372",
    bars: 3,
    pixel: 16,
    exponentialPixel: 9,
  },
  [RiskRatingEnum.Low]: {
    color: "#66A74F",
    bars: 2,
    pixel: 8,
    exponentialPixel: 3,
  },
  [RiskRatingEnum.VeryLow]: {
    color: "#59dbf2",
    bars: 1,
    pixel: 4,
    exponentialPixel: 1,
  },
  [RiskRatingEnum.Negligible]: {
    color: "#3288A1",
    bars: 0,
    pixel: 1,
    exponentialPixel: 1,
  },
  [RiskRatingEnum.Plausible]: {
    color: "#3288A1",
    bars: 0,
    pixel: 1,
    exponentialPixel: 1,
  },
  [RiskRatingEnum.NotAssessed]: {
    color: "#b3b3b3",
    bars: 0,
    pixel: 0,
    exponentialPixel: 0,
  },
  "Not Available": {
    color: "#b3b3b3",
    bars: 0,
    pixel: 0,
    exponentialPixel: 0,
  },
};

export const riskRatingsDictionary: { [typeOfRisk: string]: any } = {
  downtime: downtime,
  life_safety: lifeSafety,
  aggregate_life_safety: lifeSafety,
  inventory_loss: inventoryLoss,
  economic_loss: economicLoss,
  health_and_wellness: lifeSafety,
  damage: economicLoss,
  severe_weather_warning: downtime,
  siteaccess_roads: downtime,
  siteaccess_bridges: downtime,
  siteaccess_rail: downtime,
  utility_power: downtime,
  utility_water: downtime,
  utility_waste: downtime,
  utility_telecom: downtime,
  utility_fiber: downtime,
};

export const getRiskRatingColorsByLevel = (riskLevelName: string) => {
  return classOneRatingColors[riskLevelName];
};

export const createRating = (key: string, v: any) => {
  const isManual = v.likelihood && v.consequence;
  const lookupRating = getLookupRating(key, v);
  const rating = (isManual ? lookupRating : v.risk_rating) ?? lookupRating;

  return {
    apetite: v.apetite || undefined,
    confidence: v.confidence || undefined,
    consequence: v.consequence || undefined,
    likelihood: v.likelihood || undefined,
    risk_rating: rating,
  };
};

const getLookupRating = (key: string, v: any) => {
  const apetite = v.apetite ?? RiskAppetite.NEUTRAL;
  const r = getClassOneRiskRatings(key, apetite, v.likelihood, v.consequence);
  return r.consequence;
};

export const getClassOneRiskRatings = (
  key: string,
  apetite?: RiskAppetite | null,
  likelihood?: Likelihood | LikelihoodDetailed | null,
  consequence?: DowntimeConsequence | InjuryConsequence | PercentageRange | null
) => {
  const riskType = riskRatingsDictionary[key.toLowerCase()];
  const newValue = apetite && riskType?.[apetite];
  const likely = likelihood && newValue?.[likelihood];
  const cons = consequence && likely?.[consequence];
  return {
    key: key,
    consequence: cons,
  };
};

export const convertLikelihood = (column?: string, likelihood?: string) => {
  if (!column || !likelihood) return;

  const likelihoodFinancialInventoryLifeSafety: {
    [likelihood: string]: string;
  } = {
    [LikelihoodDetailed.Value0103]: "0.1 to 0.3 years",
    [LikelihoodDetailed.Value031]: "0.3 to 1 years",
    [LikelihoodDetailed.Value13]: "1 to 3 years",
    [LikelihoodDetailed.Value310]: "3 to 10 years",
    [LikelihoodDetailed.Value1030]: "10 to 30 years",
    [LikelihoodDetailed.Value30100]: "30 to 100 years",
    [LikelihoodDetailed.Value100300]: "100 to 300 years",
    [LikelihoodDetailed.Value3001000]: "300 to 1k years",
    [LikelihoodDetailed.Value10003000]: "1k to 3k years",
    [LikelihoodDetailed.Value300010000]: "3k to 10k years",
    [LikelihoodDetailed.Value1000030000]: "10k to 30k years",
    [LikelihoodDetailed.Value30000100000]: "30k to 100k years",
    [LikelihoodDetailed.Value100000300000]: "100k to 300k years",
    [LikelihoodDetailed.Value3000001000000]: "300k to 1m years",
  };
  const likelihoodDowntime: { [likelihood: string]: string } = {
    [Likelihood.Value011]: "0.1 to 1 years",
    [Likelihood.Value110]: "1 to 10 years",
    [Likelihood.Value10100]: "10 to 100 years",
    [Likelihood.Value1001000]: "100 to 1k years",
    [Likelihood.Value100010000]: "1k to 10k years",
    [Likelihood.Value10000100000]: "10k to 100k years",
    [Likelihood.Value1000001000000]: "100k to 1m years",
  };

  if (
    column === "inventory_loss" ||
    column === "economic_loss" ||
    column === "life_safety" ||
    column === "aggregate_life_safety" ||
    column === "health_and_wellness" ||
    column === "damage"
  ) {
    return likelihoodFinancialInventoryLifeSafety[likelihood];
  } else {
    return likelihoodDowntime[likelihood];
  }
};

export const convertConsequence = (column?: string, consequence?: string) => {
  if (!column || !consequence) return;

  const consequenceLifeSafety: { [consequence: string]: string } = {
    [InjuryConsequence.MINOR]: "Minor",
    [InjuryConsequence.MAJOR]: "Major",
    [InjuryConsequence.SEVERE]: "Severe",
    [InjuryConsequence.VERY_SEVER]: "Very Severe",
    [InjuryConsequence.FATALITY]: "Fatality",
  };
  const consequenceDowntime: { [consequence: string]: string } = {
    [DowntimeConsequence.MINUTES]: "Minutes",
    [DowntimeConsequence.HOURS]: "Hours",
    [DowntimeConsequence.DAYS]: "Days",
    [DowntimeConsequence.WEEKS]: "Weeks",
    [DowntimeConsequence.MONTHS]: "Months",
    [DowntimeConsequence.YEARS]: "Years",
  };
  const inventoryAndEconomic: { [consequence: string]: string } = {
    [PercentageRange.Value13]: "1-3%",
    [PercentageRange.Value310]: "3-10%",
    [PercentageRange.Value1030]: "10-30%",
    [PercentageRange.Value30100]: "30-100%",
  };

  if (
    column === "economic_loss" ||
    column === "inventory_loss" ||
    column === "damage"
  ) {
    return inventoryAndEconomic[consequence];
  }
  if (
    column === "life_safety" ||
    column === "aggregate_life_safety" ||
    column === "health_and_wellness"
  ) {
    return consequenceLifeSafety[consequence];
  }
  return consequenceDowntime[consequence];
};
